import { Profile, Tenant } from "@taui/shared/interfaces/openapi/adminui-api";
import { Connection } from "./connection";
import { getCollection } from "../websocket/collection";
import * as messages from "../websocket/messages";

const getTenant = (connection: Connection, slug: string) =>
  connection.sendMessagePromise<{
    tenant: Tenant;
    profile: Profile;
    currency: string;
  }>(messages.tenant(slug));

export const tenantCollection = (conn: Connection, slug: string) =>
  getCollection(
    conn,
    "_tenant",
    () =>
      getTenant(conn, slug) as Promise<{
        tenant: Tenant;
        profile: Profile;
        currency: string;
      }>,
    undefined
  );

export const subscribeTenant = (
  conn: Connection,
  slug: string,
  onChange: (data: {
    tenant: Tenant;
    profile: Profile;
    currency: string;
  }) => void
) => tenantCollection(conn, slug).subscribe(onChange);
