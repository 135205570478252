import { BackendService } from "@taui/shared/types/backend-service";
import { TauiScope } from "@taui/shared/types/taui-scope";
import { Panels } from "../types";

export const tauiStructure: Panels = {
  dashboards: {
    component_name: "dashboards",
    icon: "fad:house",
    url_path: "dashboards",
  },
  source: {
    component_name: "source",
    icon: "fad:merge",
    url_path: "source",
    services: [BackendService.cms, BackendService.crm, BackendService.studio],
    children: [
      {
        component_name: "sources",
        url_path: "source/sources",
        services: [BackendService.cms],
        children: [
          {
            component_name: "vod",
            scopes: [
              TauiScope.SOURCE_CONTENT_VIEW,
              TauiScope.SOURCE_CONTENT_EDIT,
            ],
            services: [BackendService.cms],
            url_path: "source/sources/vod",
          },
          {
            component_name: "live-streams",
            scopes: [
              TauiScope.SOURCE_CONTENT_VIEW,
              TauiScope.SOURCE_CONTENT_EDIT,
            ],
            services: [BackendService.cms],
            url_path: "source/sources/live-streams",
          },
          {
            component_name: "epg",
            scopes: [
              TauiScope.SOURCE_CONTENT_VIEW,
              TauiScope.SOURCE_CONTENT_EDIT,
            ],
            services: [BackendService.cms],
            url_path: "source/sources/epg",
          },
        ],
      },
      {
        component_name: "catalogs",
        scopes: [TauiScope.SOURCE_CONTENT_VIEW, TauiScope.SOURCE_CONTENT_EDIT],
        services: [BackendService.cms],
        url_path: "source/catalogs",
      },
      {
        component_name: "studio",
        scopes: [TauiScope.SOURCE_CONTENT_VIEW, TauiScope.SOURCE_CONTENT_EDIT],
        services: [BackendService.studio],
        url_path: "source/studio",
      },
    ],
  },
  enrich: {
    component_name: "enrich",
    icon: "fad:wand-magic-sparkles",
    url_path: "enrich",
    services: [BackendService.enrichment],
    scopes: [
      TauiScope.ENRICH_VIEW,
      TauiScope.ENRICH_EDIT,
      TauiScope.ENRICH_MANAGE,
    ],
  },
  experience: {
    component_name: "experience",
    icon: "fad:user-pen",
    url_path: "experience",
    services: [
      BackendService.cms,
      BackendService.npvr,
      BackendService.catchup,
      BackendService.publication,
      BackendService.crm,
      BackendService.realtime,
      BackendService.suboffer,
      BackendService.billing,
      BackendService.recommendation,
      BackendService.analyticsreporting,
    ],
    children: [
      {
        component_name: "vod",
        services: [BackendService.cms],
        url_path: "experience/vod",
        children: [
          {
            component_name: "vod-packages",
            scopes: [
              TauiScope.EXPERIENCE_CONTENT_VIEW,
              TauiScope.EXPERIENCE_CONTENT_EDIT,
            ],
            services: [BackendService.cms],
            url_path: "experience/vod/packages",
          },
        ],
      },
      {
        component_name: "live-streams",
        services: [
          BackendService.cms,
          BackendService.npvr,
          BackendService.catchup,
        ],
        url_path: "experience/live-streams",
        children: [
          {
            component_name: "service-plans",
            scopes: [
              TauiScope.EXPERIENCE_CONTENT_VIEW,
              TauiScope.EXPERIENCE_CONTENT_EDIT,
            ],
            services: [BackendService.cms],
            url_path: "experience/live-streams/service-plans",
          },
          {
            component_name: "live-streams-packages",
            scopes: [
              TauiScope.EXPERIENCE_CONTENT_VIEW,
              TauiScope.EXPERIENCE_CONTENT_EDIT,
            ],
            services: [
              BackendService.cms,
              BackendService.npvr,
              BackendService.catchup,
            ],
            url_path: "experience/live-streams/packages",
          },
        ],
      },
      {
        component_name: "epg-events",
        scopes: [
          TauiScope.EXPERIENCE_CONTENT_VIEW,
          TauiScope.EXPERIENCE_CONTENT_EDIT,
        ],
        services: [BackendService.cms],
        url_path: "experience/epg-events",
      },
      {
        component_name: "editorial-content",
        scopes: [
          TauiScope.EXPERIENCE_CONTENT_VIEW,
          TauiScope.EXPERIENCE_CONTENT_EDIT,
        ],
        services: [BackendService.publication],
        url_path: "experience/editorial-content",
      },
      {
        component_name: "global-images",
        scopes: [
          TauiScope.EXPERIENCE_CONTENT_VIEW,
          TauiScope.EXPERIENCE_CONTENT_EDIT,
        ],
        services: [BackendService.publication],
        url_path: "experience/global-images",
      },
      {
        component_name: "crm",
        services: [BackendService.crm],
        url_path: "experience/crm",
        children: [
          {
            component_name: "crm-configuration",
            scopes: [
              TauiScope.EXPERIENCE_USER_VIEW,
              TauiScope.EXPERIENCE_USER_EDIT,
              TauiScope.EXPERIENCE_USER_MANAGE,
            ],
            services: [BackendService.crm],
            url_path: "experience/crm/crm-configuration",
          },
          {
            component_name: "customers",
            scopes: [
              TauiScope.EXPERIENCE_USER_VIEW,
              TauiScope.EXPERIENCE_USER_EDIT,
              TauiScope.EXPERIENCE_USER_MANAGE,
            ],
            services: [BackendService.crm],
            url_path: "experience/crm/customers",
          },
        ],
      },
      {
        component_name: "communication",
        services: [BackendService.crm, BackendService.realtime],
        url_path: "experience/communication",
        children: [
          {
            component_name: "email-templates",
            scopes: [
              TauiScope.EXPERIENCE_USER_VIEW,
              TauiScope.EXPERIENCE_USER_EDIT,
              TauiScope.EXPERIENCE_USER_MANAGE,
            ],
            services: [BackendService.crm],
            url_path: "experience/communication/email-templates",
          },
          {
            component_name: "sent-emails",
            scopes: [
              TauiScope.EXPERIENCE_USER_VIEW,
              TauiScope.EXPERIENCE_USER_EDIT,
              TauiScope.EXPERIENCE_USER_MANAGE,
            ],
            services: [BackendService.crm],
            url_path: "experience/communication/sent-emails",
          },
          {
            component_name: "realtime-messages",
            scopes: [
              TauiScope.EXPERIENCE_USER_VIEW,
              TauiScope.EXPERIENCE_USER_EDIT,
              TauiScope.EXPERIENCE_USER_MANAGE,
            ],
            services: [BackendService.realtime],
            url_path: "experience/communication/realtime-messages",
          },
        ],
      },
      {
        component_name: "monetization",
        services: [BackendService.suboffer, BackendService.billing],
        url_path: "experience/monetization",
        children: [
          {
            component_name: "offers",
            scopes: [
              TauiScope.EXPERIENCE_MONETIZATION_VIEW,
              TauiScope.EXPERIENCE_MONETIZATION_EDIT,
              TauiScope.EXPERIENCE_MONETIZATION_MANAGE,
            ],
            services: [BackendService.suboffer],
            url_path: "experience/monetization/offers",
          },
          {
            component_name: "offer-groups",
            scopes: [
              TauiScope.EXPERIENCE_MONETIZATION_VIEW,
              TauiScope.EXPERIENCE_MONETIZATION_EDIT,
              TauiScope.EXPERIENCE_MONETIZATION_MANAGE,
            ],
            services: [BackendService.suboffer],
            url_path: "experience/monetization/offer-groups",
          },
          {
            component_name: "discounts",
            scopes: [
              TauiScope.EXPERIENCE_MONETIZATION_VIEW,
              TauiScope.EXPERIENCE_MONETIZATION_EDIT,
              TauiScope.EXPERIENCE_MONETIZATION_MANAGE,
            ],
            services: [BackendService.billing],
            url_path: "experience/monetization/discounts",
          },
          {
            component_name: "coupons",
            scopes: [
              TauiScope.EXPERIENCE_MONETIZATION_VIEW,
              TauiScope.EXPERIENCE_MONETIZATION_EDIT,
              TauiScope.EXPERIENCE_MONETIZATION_MANAGE,
            ],
            services: [BackendService.billing],
            url_path: "experience/monetization/coupons",
          },
          {
            component_name: "orders",
            scopes: [
              TauiScope.EXPERIENCE_USER_VIEW,
              TauiScope.EXPERIENCE_USER_EDIT,
              TauiScope.EXPERIENCE_USER_MANAGE,
            ],
            services: [BackendService.billing],
            url_path: "experience/monetization/orders",
          },
        ],
      },
      {
        component_name: "recommendation",
        scopes: [
          TauiScope.PUBLISH_CONTENT_VIEW,
          TauiScope.PUBLISH_CONTENT_EDIT,
          TauiScope.PUBLISH_CONTENT_MANAGE,
        ],
        services: [BackendService.recommendation],
        url_path: "experience/recommendation",
      },
      {
        component_name: "virtual-channels",
        url_path: "experience/virtual-channels",
        config: "featureFlag.virtualChannels",
      },
      {
        component_name: "analytics-reports",
        scopes: [
          TauiScope.EXPERIENCE_CONTENT_EDIT,
          TauiScope.EXPERIENCE_CONTENT_VIEW,
        ],
        services: [BackendService.analyticsreporting],
        url_path: "experience/analytics-reports",
      },
    ],
  },
  publish: {
    component_name: "publish",
    icon: "fad:share-nodes",
    url_path: "publish",
    services: [
      BackendService.publication,
      BackendService.identity,
      BackendService.export,
    ],
    children: [
      {
        component_name: "portals",
        scopes: [
          TauiScope.PUBLISH_CONTENT_VIEW,
          TauiScope.PUBLISH_CONTENT_EDIT,
          TauiScope.PUBLISH_CONTENT_MANAGE,
          TauiScope.PUBLISH_MANAGEMENT_VIEW,
          TauiScope.PUBLISH_MANAGEMENT_EDIT,
          TauiScope.PUBLISH_MANAGEMENT_MANAGE,
        ],
        services: [BackendService.publication],
        url_path: "publish/portals",
      },
      {
        component_name: "api-clients",
        scopes: [
          TauiScope.PUBLISH_MANAGEMENT_VIEW,
          TauiScope.PUBLISH_MANAGEMENT_EDIT,
          TauiScope.PUBLISH_MANAGEMENT_MANAGE,
        ],
        services: [BackendService.identity],
        url_path: "publish/api-clients",
      },
      {
        component_name: "ui-components",
        services: [BackendService.publication],
        url_path: "publish/ui-components",
        children: [
          {
            component_name: "app-settings",
            scopes: [
              TauiScope.PUBLISH_MANAGEMENT_VIEW,
              TauiScope.PUBLISH_MANAGEMENT_EDIT,
              TauiScope.PUBLISH_MANAGEMENT_MANAGE,
            ],
            services: [BackendService.publication],
            url_path: "publish/ui-components/app-settings",
          },
          {
            component_name: "pages",
            scopes: [
              TauiScope.PUBLISH_CONTENT_VIEW,
              TauiScope.PUBLISH_CONTENT_EDIT,
              TauiScope.PUBLISH_CONTENT_MANAGE,
            ],
            services: [BackendService.publication],
            url_path: "publish/ui-components/pages",
          },
          {
            component_name: "menus",
            scopes: [
              TauiScope.PUBLISH_CONTENT_VIEW,
              TauiScope.PUBLISH_CONTENT_EDIT,
              TauiScope.PUBLISH_CONTENT_MANAGE,
            ],
            services: [BackendService.publication],
            url_path: "publish/ui-components/menus",
          },
        ],
      },
      {
        component_name: "content-exporters",
        scopes: [
          TauiScope.PUBLISH_MANAGEMENT_VIEW,
          TauiScope.PUBLISH_MANAGEMENT_EDIT,
          TauiScope.PUBLISH_MANAGEMENT_MANAGE,
        ],
        services: [BackendService.export],
        url_path: "publish/content-exporters",
      },
    ],
  },
  settings: {
    component_name: "settings",
    icon: "fad:gear",
    url_path: "settings",
    children: [
      {
        component_name: "general-configuration",
        scopes: [
          TauiScope.SOURCE_TENANT_VIEW,
          TauiScope.SOURCE_TENANT_EDIT,
          TauiScope.SOURCE_TENANT_MANAGE,
        ],
        services: [BackendService.identity],
        url_path: "settings/general-configuration",
      },
      {
        component_name: "roles",
        scopes: [
          TauiScope.SOURCE_TENANT_VIEW,
          TauiScope.SOURCE_TENANT_EDIT,
          TauiScope.SOURCE_TENANT_MANAGE,
        ],
        services: [BackendService.identity],
        url_path: "settings/roles",
      },
      {
        component_name: "tenants",
        scopes: [
          TauiScope.SOURCE_TENANT_VIEW,
          TauiScope.SOURCE_TENANT_EDIT,
          TauiScope.SOURCE_TENANT_MANAGE,
        ],
        services: [
          BackendService.identity,
          BackendService.crm,
          BackendService.billing,
        ],
        url_path: "settings/tenants",
      },
      {
        component_name: "tenant-profiles",
        scopes: [TauiScope.SOURCE_TENANT_EDIT, TauiScope.SOURCE_TENANT_MANAGE],
        services: [BackendService.identity],
        url_path: "settings/tenant-profiles",
        isMaster: true,
      },
      {
        component_name: "backend-users",
        scopes: [
          TauiScope.EXPERIENCE_USER_VIEW,
          TauiScope.EXPERIENCE_USER_EDIT,
          TauiScope.EXPERIENCE_USER_MANAGE,
        ],
        services: [BackendService.identity],
        url_path: "settings/backend-users",
      },
      {
        component_name: "components-version",
        url_path: "settings/components-version",
      },
    ],
  },
};
