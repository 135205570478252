import { Constructor } from "../types";
import { showToast } from "../util/toast";
import { TauiBaseEl } from "./taui-base-mixin";

export default <T extends Constructor<TauiBaseEl>>(superClass: T) =>
  class extends superClass {
    private _disconnectedTimeout?: number;

    protected firstUpdated(changedProps) {
      super.firstUpdated(changedProps);

      // Need to load in advance because when disconnected, can't dynamically load code.
      setTimeout(() => import("../managers/notification-manager"), 5000);
    }

    protected tauiReconnected() {
      super.tauiReconnected();

      if (this._disconnectedTimeout) {
        clearTimeout(this._disconnectedTimeout);
        this._disconnectedTimeout = undefined;
        return;
      }

      showToast(this, {
        message: "",
        duration: 1,
      });
    }

    protected tauiDisconnected() {
      super.tauiDisconnected();

      this._disconnectedTimeout = window.setTimeout(() => {
        this._disconnectedTimeout = undefined;
        showToast(this, {
          message: this.taui!.localize("ui.notification_toast.connection_lost"),
          duration: 0,
          dismissable: false,
        });
      }, 1000);
    }
  };
