import { TucanoAdminUI } from "../types";

export const DEFAULT_TENANT = "default";

export const MASTER_TENANT = "master";

export const DEFAULT_LANGUAGES = ["eng"];
export const DEFAULT_LANGUAGE = "eng";

export const DEFAULT_CURRENCY = "EUR";

const STORAGE = window.localStorage || {};

export function getLocalTenants() {
  if (STORAGE.tenants) {
    try {
      const stored = JSON.parse(STORAGE.tenants);
      if (stored) {
        return stored;
      }
    } catch (err: any) {
      // Ignore parsing error.
    }
  }

  // Final fallback
  return [];
}

export function getLocalSelectedTenant() {
  if (STORAGE.selectedTenant) {
    try {
      const stored = JSON.parse(STORAGE.selectedTenant);
      if (stored) {
        return stored;
      }
    } catch (err: any) {
      // Ignore parsing error.
    }
  }

  // Final fallback
  return DEFAULT_TENANT;
}

export function getTenantIdBySlug(taui: TucanoAdminUI, slug: string) {
  return getTenantBySlug(taui, slug)?.id;
}

export function getTenantBySlug(taui: TucanoAdminUI, slug: string) {
  return taui?.allTenants?.find((t) => t.slug === slug);
}
